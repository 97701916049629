import Vue from 'vue'
import Vuex, { Store } from 'vuex'

// Import modules
import products from './modules/products'

Vue.use(Vuex)

const modules = {
  products
}

const store = new Store({
  state: {
    contentLang: 'ro',
    per_page: 10,
    goBackLink: null,
    socketConnected: false
  },
  getters: {
    contentLang: state => state.contentLang,
    per_page: state => state.per_page
  },
  mutations: {
    initialiseContentLang(state) {
      const contentLang = localStorage.getItem('contentLang')
      const uiLang = localStorage.getItem('lang')

      if (contentLang) {
        state.contentLang = contentLang
      } else if (uiLang) {
        state.contentLang = uiLang
      }
    },
    setContentLang: (state, code) => (state.contentLang = code),
    per_page: (state, data) => (state.per_page = data),
    goBackLink: (state, data) => (state.goBackLink = data),
    socketConnected: (state, status) => {
      state.socketConnected = status
    }
  },
  actions: {
    setContentLang: ({ commit }, code) => {
      commit('setContentLang', code)
      localStorage.setItem('contentLang', code)
    }
  },
  modules
})

export default store
