export default [
  {
    path: '/features',
    name: 'features',
    component: 'features/index',
    meta: {
      auth: ['root', 'features:read']
    }
  },
  {
    path: '/features/add',
    name: 'FeatureAdd',
    component: 'features/FeatureEdit',
    meta: {
      auth: ['root', 'features:add']
    }
  },
  {
    path: '/features/id-:feature_id',
    name: 'FeatureEdit',
    component: 'features/FeatureEdit',
    meta: {
      auth: ['root', 'features:update']
    }
  },
  {
    path: '/features/id-:feature_id/variants',
    name: 'VariantsEdit',
    component: 'features/VariantsEdit'
  }
]
