const state = {
  product: {}
}

const getters = {
  product: state => state.product
}

const mutations = {
  product: (state, payload) => (state.product = payload)
}

const actions = {
  product: (ctx, payload) => {
    ctx.commit('product', payload)
  }
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true
}
