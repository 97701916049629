<template>
  <div class="btn-group btn-group-sm">
    <button
      v-for="(lang, key) in languages"
      :key="key"
      :class="{
        'btn': true,
        'btn-secondary': true,
        'btn-success': lang.code === contentLang,
        'active': lang.code === contentLang
      }"
      @click="switchLang(lang.code)"
    >
      {{ lang.name }}
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'LangSwitcher',
  data: () => ({
    languages: [
      {
        code: 'ro',
        name: 'română'
      },
      {
        code: 'ru',
        name: 'русский'
      }
    ]
  }),
  computed: {
    ...mapGetters({
      contentLang: 'contentLang'
    })
  },
  methods: {
    switchLang(code) {
      this.$store.dispatch('setContentLang', code)
      this.$emit('change')
    }
  }
}
</script>
