import Vue from 'vue'
import vSelect from 'vue-select'

import 'vue-select/dist/vue-select.css'

vSelect.props.components.default = () => ({
  Deselect: {
    render: createElement =>
      createElement(
        'span',
        {
          class: {
            'vs-select__chips__chip__close': true
          }
        },
        [
          createElement('i', {
            class: {
              'vs-icon-close': true,
              'vs-icon-hover-less': true
            }
          })
        ]
      )
  },
  OpenIndicator: {
    render: createElement =>
      createElement('i', {
        class: {
          'bx': true,
          'bx-chevron-down': true
        }
      })
  }
})

Vue.component('VSelect', vSelect)
