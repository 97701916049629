<template>
  <component :is="layout">
    <transition name="fade-transform" mode="out-in">
      <router-view :key="$route.path" />
    </transition>
  </component>
</template>

<script>
export default {
  name: 'App',
  computed: {
    layout() {
      return (this.$route.meta.layout || 'unauthorized') + '-layout'
    }
  }
}
</script>

<style lang="stylus">
.fade-transform-leave-active,
.fade-transform-enter-active
    transition: all .15s

.fade-transform-enter
    opacity: 0
    transform: scale(1.1)

.fade-transform-leave-to
    opacity: 0
    transform: scale(0.9)
</style>
