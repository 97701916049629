import Vue from 'vue'
import VueLazyload from 'vue-lazyload'

Vue.use(VueLazyload, {
  preLoad: 0.9,
  observer: true,
  attempt: 1,
  lazyComponent: true,
  throttleWait: 500,
  loading: require('@/assets/images/loading.svg'),
  error: require('@/assets/images/error.svg')
})
