export default [
  {
    path: '/groups',
    name: 'Groups',
    component: 'groups/index'
  },
  {
    path: '/groups/add',
    name: 'GroupAdd',
    component: 'groups/GroupDetails'
  },
  {
    path: '/groups/id-:group_id',
    name: 'GroupDetails',
    component: 'groups/GroupDetails'
  }
]
