import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/**
 * Import plugins
 */
import './plugins/axios'
import './plugins/vuesax'
import './plugins/popConfirm'
import './plugins/langSwitcher'
import './plugins/dnd'
import './plugins/vueSelect'
import './plugins/vueColor'
import './plugins/vueClipboard'
import './plugins/ContextMenu'
import './plugins/uikit'
import './plugins/infiniteLoading'
import './plugins/vueLazyLoad'
import './plugins/vueGrid'

/**
 * Import filters
 */
import './filters/toDate'

/**
 * Import styles
 */
import './assets/styles/main.styl'
import './assets/styles/main.scss'

/**
 * Import authorization module
 */
import VueAuth from '@websanova/vue-auth'
import AuthDriver from '@websanova/vue-auth/drivers/auth/bearer'
import HttpDriver from '@websanova/vue-auth/drivers/http/axios.1.x'
import RouterDriver from '@websanova/vue-auth/drivers/router/vue-router.2.x'
import i18n from './plugins/i18n'
import VueSocketIO from 'vue-socket.io'
import { io } from 'socket.io-client'

/**
 * Enable authorization module
 */
Vue.router = router
Vue.use(VueAuth, {
  auth: AuthDriver,
  http: HttpDriver,
  router: RouterDriver,
  tokenDefaultName: 'accessToken',
  stores: ['cookie'],
  cookie: {
    Secure: true,
    SameSite: 'Strict'
  },
  parseUserData: data => data,
  refreshData: {
    enabled: false
  },
  authRedirect: { path: '/auth' }
})

Vue.use(
  new VueSocketIO({
    debug: process.env.NODE_ENV !== 'production',
    connection: io({
      path: '/api/v1/socket.io',
      transports: ['websocket']
    })
  })
)

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  beforeCreate() {
    this.$store.commit('initialiseContentLang')
  },
  sockets: {
    connect() {
      this.$store.commit('socketConnected', true)
    },
    connect_failed: () => {},
    connect_error: () => {},
    disconnect() {
      this.$store.commit('socketConnected', false)
    }
  },
  render: h => h(App)
}).$mount('#app')
