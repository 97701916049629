export default [
  {
    path: '/categories',
    name: 'Categories',
    component: 'categories/index',
    meta: {
      auth: ['root', 'categories:read']
    }
  },
  {
    path: '/categories/add',
    name: 'CategoryAdd',
    component: 'categories/CategoryEdit',
    meta: {
      auth: ['root', 'categories:add']
    }
  },
  {
    path: '/categories/id-:category_id',
    name: 'CategoryEdit',
    component: 'categories/CategoryEdit',
    meta: {
      auth: ['root', 'categories:update']
    }
  },
  {
    path: '/categories/id-:category_id/endpoints',
    name: 'CategoryEndpoints',
    component: 'categories/CategoryEndpoints'
  },
  {
    path: '/categories/id-:category_id/sort',
    name: 'CategorySorting',
    component: 'categories/CategorySorting'
  }
]
