<template>
  <vs-tooltip
    v-model="active"
    border-thick
    not-hover
    :top="top"
    :bottom="bottom"
    :left="left"
    :right="right"
    class="d-inline"
    @click.native="confirm"
  >
    <slot />
    <template #tooltip>
      <div class="my-1">
        {{ title || $t('are_you_sure') }}
        <small class="d-block text-mini">{{ subTitle }}</small>
      </div>
      <div class="d-flex justify-center">
        <vs-button size="mini" success @click="submit">
          {{ $t('misc.yes') }}
        </vs-button>
        <vs-button size="mini" danger @click="active = false">
          {{ $t('misc.cancel') }}
        </vs-button>
      </div>
    </template>
  </vs-tooltip>
</template>

<script>
export default {
  name: 'PopConfirm',
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    },
    top: {
      type: Boolean,
      default: true
    },
    bottom: {
      type: Boolean,
      default: false
    },
    left: {
      type: Boolean,
      default: false
    },
    right: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    active: false
  }),
  methods: {
    confirm() {
      if (
        this.$slots.default.some(
          elem => elem.data.attrs && elem.data.attrs.disabled
        )
      )
        return
      this.active = true
    },
    submit() {
      this.active = false
      setTimeout(() => this.$emit('confirm'), 100)
    }
  }
}
</script>
