export default [
  {
    path: '/related',
    name: 'Related',
    component: 'related/index'
  },
  {
    path: '/related/add',
    name: 'addRelated',
    component: 'related/edit'
  }
]
