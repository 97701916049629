export default [
  {
    path: '/erp',
    name: 'Erp',
    component: 'erp/index',
    meta: {
      auth: ['root', 'erp:read']
    }
  },
  {
    path: '/erp/sync',
    name: 'ErpSync',
    component: 'erp/erpSync',
    meta: {
      auth: ['root', 'erp:sync:read']
    }
  }
]
