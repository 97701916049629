export default [
  {
    path: '/products',
    name: 'Products',
    component: 'products/index'
  },
  {
    path: '/products/add',
    name: 'ProductAdd',
    component: 'products/edit'
  },
  {
    path: '/products/id-:product_id',
    name: 'ProductEdit',
    component: 'products/edit'
  },
  {
    path: '/products/id-:product_id/features',
    name: 'ProductEditFeatures',
    component: 'products/editFeatures'
  },
  {
    path: '/products/id-:product_id/images',
    name: 'ProductEditImages',
    component: 'products/editImages'
  },
  {
    path: '/products/id-:product_id/video',
    name: 'ProductEditVideo',
    component: 'products/editVideo'
  },
  {
    path: '/products/id-:product_id/endpoints',
    name: 'ProductEndpoints',
    component: 'products/editEndpoints'
  },
  {
    path: '/products/id-:product_id/categories',
    name: 'ProductAdditionalCategories',
    component: 'products/editCategories'
  },
  {
    path: '/products/id-:product_id/fusions',
    name: 'ProductFusions',
    component: 'products/Fusions'
  },
  {
    path: '/products/id-:product_id/manufacturer-urls',
    name: 'ManufacturerUrls',
    component: 'products/manufacturerUrls'
  },
  {
    path: '/products/id-:product_id/history',
    name: 'ProductAdditionalHistory',
    component: 'products/history'
  },
  {
    path: '/products/id-:product_id/related',
    name: 'relatedProducts',
    component: 'products/related'
  }
]
