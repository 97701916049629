export default [
  {
    path: '/endpoints',
    name: 'Endpoints',
    component: 'endpoints/index'
  },
  {
    path: '/endpoints/add',
    name: 'EndpointAdd',
    component: 'endpoints/edit'
  },
  {
    path: '/endpoints/id-:endpoint_id',
    name: 'EndpointEdit',
    component: 'endpoints/edit'
  }
]
