import Vue from 'vue'
import axios from 'axios'
import VueAxios from 'vue-axios'
import store from '../store'
import i18n from './i18n'

axios.defaults.baseURL = '/api/v1'

axios.interceptors.response.use(
  response => {
    if (response.config.method === 'patch' && response.status === 200) {
      const vm = new Vue()

      if (response.config.toast !== false) {
        vm.$vs.notification({
          text: i18n.t('misc.operation_successfully'),
          color: 'success',
          position: 'bottom-right',
          icon: '<i class="bx bx-check" ></i>',
          duration: 2000
        })
      }
    }
    return response
  },
  async error => {
    // Handle server errors
    if (error.response && error.response.data) {
      const vm = new Vue()

      // Handle server logout
      if (error.response.status === 401)
        await vm.$auth.logout({
          redirect: '/auth',
          makeRequest: false
        })
      // Display errors
      else {
        vm.$vs.notification({
          title: 'Eroare ' + error.response.status,
          text: error.response.data.message || error.response.data,
          color: 'danger',
          position: 'bottom-right',
          icon: '<i class="bx bxs-bug" ></i>',
          progress: 'auto',
          duration: 5000
        })
      }
    }

    return Promise.reject(error)
  }
)

axios.interceptors.request.use(
  function (config) {
    config.headers.lang = ~config.url.search(/^\/(forms|kanban)/)
      ? i18n.locale
      : store.getters['contentLang']

    return config
  },
  function (err) {
    return Promise.reject(err)
  }
)

Vue.use(VueAxios, axios)

export const api2 = axios.create({ baseURL: '/api/v2', params: {} })

api2.interceptors.request.use(
  function (config) {
    config.params.locale = store.getters['contentLang']

    return config
  },
  function (err) {
    return Promise.reject(err)
  }
)
