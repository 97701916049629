import users from './users'
import groups from './groups'
import categories from './categories'
import products from './products'
import features from './features'
import endpoints from './endpoints'
import profile from './profile'
import importRoute from './import'
import erp from './erp'
import related from './related'
import kanban from './kanban'
import productsOldReasons from '@/router/routes/products-old-reasons'

const routes = [
  ...users,
  ...groups,
  ...categories,
  ...products,
  ...features,
  ...endpoints,
  ...profile,
  ...erp,
  ...importRoute,
  ...related,
  ...kanban,
  ...productsOldReasons,
  {
    path: '/auth',
    name: 'Auth',
    component: 'Auth',
    meta: {
      auth: false,
      layout: 'unauthorized'
    }
  },
  {
    path: '/',
    name: 'Home',
    redirect: '/kanban'
  },
  {
    path: '/403',
    name: 'Denied',
    component: 'Denied'
  },
  {
    path: '/404',
    alias: '*', // Handle all not existing pages
    name: 'Error',
    component: 'Error'
  }
]

const load = component => () => import(`@/views/${component}.vue`)
export default routes.map(route => {
  route.component = load(route.component)
  return route
})
