import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'
import routes from './routes'

let loading = null
const vm = new Vue()

Vue.use(VueRouter)
Vue.use(VueMeta)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

function importLayout(name) {
  if (!Vue.options.components[name + '-layout']) {
    Vue.component(name + '-layout', () => import(`@/layouts/${name}.vue`))
  }
}

// Import default layout
importLayout('unauthorized')

router.beforeEach((to, from, next) => {
  // Set auth required to all routes
  if (typeof to.meta.auth === 'undefined') to.meta.auth = true

  const layout = to.meta.layout ? to.meta.layout : (to.meta.layout = 'default')
  importLayout(layout)

  if (loading) loading.close()
  loading = vm.$vs.loading({
    type: 'circles'
  })

  next()
})

router.afterEach(() => {
  setTimeout(() => loading.close(), 100)
})

router.onError(err => {
  if (loading) loading.close()
  console.log(err)
})

export default router
