export default [
  {
    path: '/import',
    name: 'Import',
    component: 'import/index',
    meta: {
      auth: ['root', 'import:read']
    }
  },
  {
    path: '/import/:import_id',
    name: 'EditImport',
    component: 'import/editImport'
  }
]
